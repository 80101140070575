import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { PropsWithChildren } from 'react'

type ColorDotProps = PropsWithChildren<{
  color: string
  size?: number
  sx?: SxProps
  onClick?: () => void
}>

export default function ColorDot({ color, size = 8, sx, ...rest }: ColorDotProps) {
  return (
    <Box
      {...rest}
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'inline-block',
        ...sx
      }}
    />
  )
}
