import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { Duration } from 'dayjs/plugin/duration'
import { identity } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useLogger from '~/debug/logger'
import { useUserPrefs } from '~/hooks/useUserPrefs'
import useWindowFocus from '~/hooks/useWindowFocus'

import type { Tuple } from '../types/arrays'
import type { DayTransform, TimeRange } from './types'

/**
 * Returns a memoized Dayjs object. Consecutive calls to the returned function will return the exact
 * same Dayjs object if the operation applied to the input date yields an output date that is the same
 * as the previous output date. This reference equality is valuable for preventing unnecessary
 * re-renders of components which might want to use the Dayjs object as a dependency in a hook.
 *
 * @param date - Input date that can be passed into the Dayjs constructor. Defaults to the current time.
 * @param operation - A function to transform the dayjs object.
 * @returns - The input date transformed by the provided function.
 */
export function useTimeMemo(date?: dayjs.ConfigType, operation: DayTransform = identity): Dayjs {
  const { tzDayjs } = useUserPrefs()
  const [memoDate, setMemoDate] = useState(() => operation(tzDayjs(date)))

  useEffect(() => {
    const newDate = operation(tzDayjs(date))
    if (!newDate.isSame(memoDate)) {
      setMemoDate(newDate)
    }
  }, [date, operation, memoDate, tzDayjs])

  return memoDate
}

/**
 * Convenience method for `useTimeMemo` that defaults to the current time rounded down to the minute.
 */
export function useNowMemo(operation: DayTransform = (d) => d.startOf('minute')): Dayjs {
  const [now, setNow] = useState(() => dayjs())

  // Keep track of window focus so that the memoized `now` value is only updated when the window is
  // focused. This is to prevent unnecessary API calls when the window is not focused. The value is
  // stored in a ref so that it can be accessed in the `useEffect` hook below without passing it as
  // a dependency.
  const isFocused = useWindowFocus()
  const focusRef = useRef(isFocused)
  useEffect(() => {
    // Update the ref when the focus changes. Additionally, when the window regains focus, update
    // the `now` value to immediately trigger the update.
    focusRef.current = isFocused
    if (isFocused) setNow(dayjs())
  }, [isFocused])

  // Update the `now` state every 5 seconds. This will trigger the `useEffect` hook in `useTimeMemo`
  // to re-execute, which will update the memoized `now` value if the current time has changed after
  // being passed through the `operation` function.
  useEffect(() => {
    const intervalId = setInterval(() => focusRef.current && setNow(dayjs()), 5_000)
    return () => clearInterval(intervalId)
  }, [])

  return useTimeMemo(now, operation)
}

type TimeRangeOptions = {
  duration?: number | Duration
  start?: dayjs.ConfigType | 'today'
  end?: dayjs.ConfigType | 'today'
  unit: dayjs.OpUnitType
}

type TimeRangeControls = {
  fromOptions(options: TimeRangeOptions): void
  fromTuple(tuple: Tuple<dayjs.ConfigType>): void
}

export type TimeRangeWrapper = {
  timeRange: TimeRange
  controls: TimeRangeControls
}

/**
 * Generates a `TimeRange` object based on the provided options, and returns convenient methods for
 * updating the range.
 */
export function useTimeRange(options: TimeRangeOptions): TimeRangeWrapper {
  const { tzDayjs } = useUserPrefs()
  const logger = useLogger('useTimeRange')
  const optionsError = useMemo(() => validateOptions(options), [options])
  if (optionsError) {
    logger.error(optionsError)
  }

  // Parse the options and round to the start of the specified unit (or day by default)
  const { unit } = options
  const getCurrentTime = useCallback(() => tzDayjs().startOf(unit), [tzDayjs, unit])
  const [timeRange, setTimeRange] = useState<TimeRange>(() => {
    const [start, end] = parseRangeOptions(options, getCurrentTime())
    return { from: start.startOf(unit), to: end.endOf(unit) }
  })

  // Provides convenient methods for setting the time range
  const controls = useMemo<TimeRangeControls>(() => {
    return {
      fromOptions(options: TimeRangeOptions) {
        const [start, end] = parseRangeOptions(options, getCurrentTime())
        setTimeRange({ from: start.startOf(unit), to: end.endOf(unit) })
      },

      fromTuple([from, to]: Tuple<dayjs.ConfigType>) {
        if (dayjs(from).isValid() && dayjs(to).isValid())
          setTimeRange({ from: dayjs(from).startOf(unit), to: dayjs(to).endOf(unit) })
      }
    }
  }, [getCurrentTime, unit])

  return { timeRange, controls }
}

/**
 * For a `TimeRangeOptions` object to be valid, it must be unambiguous. This means that exactly 2 of
 * the 3 properties must be provided. If all 3 are provided the object will be considered invalid,
 * even if the values are consistent.
 */
function validateOptions({ duration, start, end }: TimeRangeOptions) {
  if (duration && start && end) {
    return 'Cannot specify both duration and start/end'
  }

  const providedOptions = [duration, start, end].filter((o) => o !== undefined)
  if (providedOptions.length !== 2) {
    return 'Must specify exactly 2 of `duration`, `start`, and `end`'
  }
}

/**
 * Parses the provided `TimeRangeOptions` object into a tuple of Dayjs objects representing the
 * start and end of the range. If the `start` or `end` properties are set to `'today'`, they will be
 * replaced with the current time.
 *
 * If the options are invalid, the current time will be used for both the start and end of the range.
 * This is somewhat arbitrary. It's intended to prevent inadvertently making API requests with hazardously
 * large date ranges.
 */
function parseRangeOptions(options: TimeRangeOptions, now: Dayjs): Tuple<Dayjs> {
  const { duration, start: startArg, end: endArg } = options
  const start = startArg === 'today' ? now : startArg
  const end = endArg === 'today' ? now : endArg

  if (start && end) {
    return [dayjs(start), dayjs(end)]
  } else if (start && duration) {
    const startDay = dayjs(start)
    if (typeof duration === 'number') {
      return [startDay, startDay.add(duration, 'days')]
    } else {
      return [startDay, startDay.add(duration)]
    }
  } else if (end && duration) {
    const endDay = dayjs(end)
    if (typeof duration === 'number') {
      return [endDay.subtract(duration, 'days'), endDay]
    } else {
      return [endDay.subtract(duration), endDay]
    }
  } else {
    return [now, now]
  }
}
