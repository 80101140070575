import type { SxProps } from '@mui/material'
import { Box, Stack } from '@mui/material'
import type { PropsWithChildren } from 'react'

type CenterProps<T extends string> = { sx?: SxProps } & PropsWithChildren<{
  [K in T]?: string | number
}>

const Horizontal = ({ children, width = '100%', sx }: CenterProps<'width'>) => (
  <Stack direction="row" sx={{ ...sx, width, justifyContent: 'center' }}>
    {children}
  </Stack>
)

const Vertical = ({ children, height = '100%', sx }: CenterProps<'height'>) => (
  <Stack direction="column" sx={{ ...sx, height, justifyContent: 'center' }}>
    {children}
  </Stack>
)

const Middle = ({ children, width = '100%', height = '100%', sx }: CenterProps<'width' | 'height'>) => (
  <Box display="flex" alignItems="center" justifyContent="center" sx={{ ...sx, width, height }}>
    {children}
  </Box>
)

export default {
  Horizontal,
  Middle,
  Vertical
}
