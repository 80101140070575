// =============================================================================
// Route constants
// =============================================================================
const ALERTS_ROUTE = 'alerts'
const CHARGERS_ROUTE = 'chargers'
const DASHBOARD_ROUTE = 'dashboard'
const SETTINGS_ROUTE = 'settings'
const SITES_ROUTE = 'sites'
const TRANSACTIONS_ROUTE = 'transactions'
const VEHICLES_ROUTE = 'vehicles'
const TRIPS_ROUTE = 'trips'

const makeRoute = (...components: Array<string | number>) => `/${components.join('/')}/`
const entityRouteFn = (entityRoute: string) => (entityId: string) => makeRoute(entityRoute, entityId)

const dashboardPage = entityRouteFn(DASHBOARD_ROUTE)
const settingsPage = entityRouteFn(SETTINGS_ROUTE)

export type RouteDirectory = typeof routeDirectory
const routeDirectory = {
  alerts: {
    root: makeRoute(ALERTS_ROUTE),
    new: makeRoute(ALERTS_ROUTE, 'new'),
    edit: (alertId: string) => makeRoute(ALERTS_ROUTE, alertId, 'edit')
  },
  chargers: {
    root: makeRoute(CHARGERS_ROUTE),
    details: entityRouteFn(CHARGERS_ROUTE)
  },
  dashboard: {
    root: makeRoute(DASHBOARD_ROUTE),
    chargers: dashboardPage('chargers'),
    vehicles: dashboardPage('vehicles')
  },
  notifications: makeRoute('notifications'),
  reports: makeRoute('reports'),
  resetPassword: makeRoute('password', 'reset'),
  index: '/',
  settings: {
    root: makeRoute(SETTINGS_ROUTE),
    alerts: settingsPage('alerts'),
    apiKeys: settingsPage('api-keys'),
    billing: settingsPage('billing'),
    chargers: {
      root: settingsPage('chargers'),
      new: makeRoute(SETTINGS_ROUTE, CHARGERS_ROUTE, 'new'),
      edit: (chargerId: string) => makeRoute(SETTINGS_ROUTE, CHARGERS_ROUTE, chargerId, 'edit')
    },
    dataSources: settingsPage('data-sources'),
    fleets: settingsPage('fleets'),
    orgManagement: settingsPage('org-mgmt'),
    locations: settingsPage('locations'),
    orgs: settingsPage('organizations'),
    sites: {
      root: settingsPage(SITES_ROUTE),
      new: makeRoute(SETTINGS_ROUTE, SITES_ROUTE, 'new'),
      edit: (siteId: string) => makeRoute(SETTINGS_ROUTE, SITES_ROUTE, siteId, 'edit')
    },
    themes: settingsPage('themes'),
    users: settingsPage('users'),
    vehicles: settingsPage('vehicles'),
    fleetBilling: settingsPage('fleet-billing'),
    homeCharging: settingsPage('home-charging'),
    workplaceCharging: settingsPage('workplace-charging'),
    costExplorer: settingsPage('cost-explorer')
  },
  sites: {
    root: makeRoute(SITES_ROUTE),
    details: entityRouteFn(SITES_ROUTE)
  },
  transactions: entityRouteFn(TRANSACTIONS_ROUTE),
  vehicles: {
    root: makeRoute(VEHICLES_ROUTE),
    details: entityRouteFn(VEHICLES_ROUTE),
    trips: {
      root: (vehicleId: string) => makeRoute(VEHICLES_ROUTE, vehicleId, TRIPS_ROUTE),
      details: (vehicleId: string, tripId: string) => makeRoute(VEHICLES_ROUTE, vehicleId, TRIPS_ROUTE, tripId)
    }
  }
}

export default routeDirectory
