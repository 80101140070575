import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { dispatcherFactory, selectorHookFactory } from '~/store/utils'

export type SortDir = 'asc' | 'desc'
export type SortKey = 'vehicleNm' | 'stateOfCharge' | 'remainingRange' | 'vehicleStatus'

type VehicleOverviewSlice = {
  orgIds: string[]
  siteIds: string[]
  searchString: string
  selectedId?: string
  sortBy: {
    key: SortKey
    dir: SortDir
  }
}

const initialState: VehicleOverviewSlice = {
  orgIds: [],
  siteIds: [],
  searchString: '',
  sortBy: {
    key: 'vehicleNm',
    dir: 'asc'
  }
}

const vehicleOverviewSlice = createSlice({
  name: 'vehicleOverview',
  initialState,
  reducers: {
    reset: () => initialState,
    setOrgIds: (state, action: PayloadAction<string[]>) => ({ ...state, orgIds: action.payload }),
    setSiteIds: (state, action: PayloadAction<string[]>) => ({ ...state, siteIds: action.payload }),
    setSearchString: (state, action: PayloadAction<string>) => ({ ...state, searchString: action.payload }),

    // Passed in as a string for convenience. Key and direction should be separated by a colon.
    setSortBy: (state, action: PayloadAction<string>) => {
      const [key, dir] = action.payload.split(':') as [SortKey, SortDir]
      return { ...state, sortBy: { key, dir } }
    },

    toggleSelectedId: (state, action: PayloadAction<string>) => {
      const selectedId = state.selectedId === action.payload ? undefined : action.payload
      return { ...state, selectedId }
    }
  }
})

export default vehicleOverviewSlice.reducer

export const useSelectedOrgIds = selectorHookFactory((state) => state.vehicleOverview.orgIds)
export const useSelectedSiteIds = selectorHookFactory((state) => state.vehicleOverview.siteIds)
export const useSearchString = selectorHookFactory((state) => state.vehicleOverview.searchString)
export const useSelectedId = selectorHookFactory((state) => state.vehicleOverview.selectedId)
export const useSortBy = selectorHookFactory((state) => state.vehicleOverview.sortBy)

const { reset, setOrgIds, setSiteIds, setSearchString, toggleSelectedId, setSortBy } = vehicleOverviewSlice.actions
export { reset }

export const useUpdateOrgIds = dispatcherFactory(setOrgIds)
export const useUpdateSiteIds = dispatcherFactory(setSiteIds)
export const useUpdateSearchString = dispatcherFactory(setSearchString)
export const useToggleSelectedId = dispatcherFactory(toggleSelectedId)
export const useUpdateSortBy = dispatcherFactory(setSortBy)
