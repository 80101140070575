import { useEffect, useState } from 'react'

// Tracks the window focus state. This is updated in the event listeners below and
// is used to initialize local state within the hook.
let globalIsFocused = true

// Set of listeners to notify on change
type Listener = (isFocused: boolean) => void
const listeners = new Set<Listener>()

function notifyListeners(isFocused: boolean) {
  globalIsFocused = isFocused
  listeners.forEach((listener) => listener(isFocused))
}

// Will be set to `true` in the first call to `useWindowFocus()`
let listenersAreAttached = false

export default function useWindowFocus() {
  const [isFocused, setIsFocused] = useState(globalIsFocused)

  useEffect(() => {
    if (listenersAreAttached) return
    listenersAreAttached = true

    // Set up global event listeners
    window.addEventListener('focus', () => notifyListeners(true))
    window.addEventListener('blur', () => notifyListeners(false))
  }, [])

  useEffect(() => {
    // Listener function
    const listener = (newFocusState: boolean) => {
      setIsFocused(newFocusState)
    }

    // Add listener to the global set
    listeners.add(listener)

    // Remove listener from the global set on unmount
    return () => {
      listeners.delete(listener)
    }
  }, [])

  return isFocused
}
