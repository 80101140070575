import { useCallback } from 'react'

import type { ChargingTransactionReportRow } from '~/app/reports/hooks/useChargingTransactionsReport'
import useIsAdmin from '~/auth/hooks/useIsAdmin'
import type { MaybeStringKeyOf } from '~/data/types/narrow'
import { getKeyOf } from '~/data/types/narrow'

import type { ChargerModel, DepotModel, VehicleModel } from '../client'
import { useCurrentUser } from './users'

export function useSiteHomeSiteFilterCallback() {
  return useHomeSiteFilter<DepotModel>('depotId')
}

export function useChargerHomeSiteFilterCallback() {
  return useHomeSiteFilter<ChargerModel>('depotId')
}

export function useChargerTransactionHomeSiteFilterCallback() {
  return useHomeSiteFilter<ChargingTransactionReportRow>('depotId')
}

export function useVehicleHomeSiteFilterCallback() {
  return useHomeSiteFilter<VehicleModel>('homeSiteId')
}

function useHomeSiteFilter<T>(idKey: MaybeStringKeyOf<T>) {
  const { synopUser } = useCurrentUser()
  const isAdmin = useIsAdmin()

  return useCallback(
    (entity: T) => {
      if (!synopUser) return false
      if (isAdmin) return true
      if (!synopUser.sites || synopUser.sites.length === 0) return true

      const value = getKeyOf(entity, idKey)
      if (!value) return false
      return synopUser.sites.includes(value)
    },
    [synopUser, isAdmin, idKey]
  )
}
