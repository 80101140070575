import dayjs, { type Dayjs } from 'dayjs'
import { mapKeys } from 'lodash'
import { useMemo } from 'react'

import type { UsePollingOptions } from './polling'
import type { MaybeDateRange, ValidatedTime } from './types'

export type ValidatedRange = {
  from: ValidatedTime
  to: ValidatedTime
  range: {
    valid: boolean
    errors: string[]
  }
}

type ValidateDateRangeOptions = {
  maxDays?: number
}

type ValidTimeMode = 'START' | 'END'

/**
 * Validates the date range and returns a rounded version of the dates.
 */
export function useValidatedDateRange({ from, to }: MaybeDateRange = {}, { maxDays }: ValidateDateRangeOptions = {}) {
  const validatedFrom = useMemo(() => validateTime(from, 'START'), [from])
  const validatedTo = useMemo(() => validateTime(to, 'END'), [to])

  return useMemo<ValidatedRange>(() => {
    let rangeValid = true
    const errors = []

    if (!validatedFrom.valid) {
      rangeValid = false
      errors.push('Invalid start date')
    }

    if (!validatedTo.valid) {
      rangeValid = false
      errors.push('Invalid end date')
    }

    // If they are both valid, check the range
    if (validatedFrom.valid && validatedTo.valid) {
      const toTime = dayjs(validatedTo.time)

      if (toTime.isSameOrBefore(validatedFrom.time)) {
        rangeValid = false
        errors.push('Start date must be before end date')
      }

      if (maxDays && toTime.diff(validatedFrom.time, 'day') > maxDays) {
        rangeValid = false
        errors.push(`Date range must be within ${maxDays} days`)
      }
    }

    return {
      from: validatedFrom,
      to: validatedTo,
      range: { valid: rangeValid, errors }
    }
  }, [validatedFrom, validatedTo, maxDays])
}

function validateTime(time?: Dayjs, timeMode: ValidTimeMode = 'START'): ValidatedTime {
  const isValid = time?.isValid() ?? false
  const validTime = timeMode === 'START' ? time?.startOf('minute').toISOString() : time?.endOf('minute').toISOString()
  return {
    valid: isValid,
    time: (isValid && validTime) || ''
  }
}

/**
 * Combines the skip option from the hook with the skip option from the caller. If
 * either the hook or the caller wants to skip, then the query will be skipped. This
 * is useful for omitting boilerplate code in the caller, such as `skip: !siteId`.
 */
export function combineSkip(ownSkip: boolean, options?: UsePollingOptions) {
  const optionsSkip = options?.skip ?? false
  return { ...options, skip: ownSkip || optionsSkip }
}

/**
 * Converts the input data to a case-insensitive dictionary. This is useful for looking up data by
 * key in a case-insensitive manner.
 *
 * @param data The input data to be converted to a case-insensitive dictionary
 */
export function useCaseInsensitiveDict<T>(data: Record<string, T>) {
  return useMemo(() => {
    const upper = mapKeys(data, (_, key) => key.toUpperCase())
    return new Proxy(upper, {
      get: function (target, prop) {
        // Convert the property name to uppercase before lookup
        return target[prop.toString().toUpperCase()]
      }
    })
  }, [data])
}
