import { OpenInNew } from '@mui/icons-material'
import { IconButton, Tooltip, Typography, styled } from '@mui/material'
import Link from 'next/link'

type LinkProps = { noWrap?: boolean }

export default styled(Link, { shouldForwardProp: (prop) => prop !== 'noWrap' })<LinkProps>(({ theme, noWrap }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  ...(noWrap && {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
}))

// Pretends to be a link, but is not.
export const LinkTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer'
}))

type OpenInNewTabIconProps = {
  href: string
}

export const OpenInNewTabIcon = ({ href }: OpenInNewTabIconProps) => {
  return (
    <Tooltip
      title="Open in new tab"
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10]
              }
            }
          ]
        }
      }}
    >
      <IconButton LinkComponent={Link} href={href} target="_blank">
        <OpenInNew fontSize="small" color="primary" />
      </IconButton>
    </Tooltip>
  )
}
