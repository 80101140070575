import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { reset as resetSitesOverview } from '~/app/sites/slice'
import { reset as resetVehiclesOverview } from '~/app/vehicles/slice'
import type { OrganizationModel } from '~/data/api/client'
import type { PartialStore } from '~/store/types'

import { dispatcherFactory, selectorHookFactory } from '../utils'

type CloakedOrg = { name?: string; parentName?: string; id: string }
type CurrentOrganizationStore = PartialStore<typeof currentOrganizationSlice>
type CurrentOrganizationState = {
  principalOrgId: string
  cloakedOrgs: CloakedOrg[]
}

const initialState: CurrentOrganizationState = {
  principalOrgId: '',
  cloakedOrgs: []
}

const currentOrganizationSlice = createSlice({
  name: 'currentOrganization',
  initialState,
  reducers: {
    setPrincipalOrganization: (state, action: PayloadAction<string | undefined>) => {
      const id = action.payload
      if (id === undefined) return

      state.principalOrgId = id
      state.cloakedOrgs = [{ id }]
    },
    cloakAsOrganization: (state, action: PayloadAction<CloakedOrg>) => {
      state.cloakedOrgs.push(action.payload)
    },
    revertToPreviousOrganization: (state) => {
      if (state.cloakedOrgs.length > 1) {
        state.cloakedOrgs.pop()
      }
    },
    resetCloaking: (state) => {
      // Practically speaking this should always be true
      state.cloakedOrgs = [{ id: state.principalOrgId }]
    }
  }
})

export const { setPrincipalOrganization, cloakAsOrganization, revertToPreviousOrganization, resetCloaking } =
  currentOrganizationSlice.actions

export default currentOrganizationSlice.reducer

const selectPrincipalOrgId = (state: CurrentOrganizationStore) => state.currentOrganization.principalOrgId
const selectCurrentOrgId = (state: CurrentOrganizationStore) => state.currentOrganization.cloakedOrgs.at(-1)?.id ?? ''
const selectCloakedOrg = (state: CurrentOrganizationStore) => state.currentOrganization.cloakedOrgs.at(-1)

const selectIsCloaking = createSelector(selectCurrentOrgId, selectPrincipalOrgId, (currentOrgId, principalOrgId) => {
  return principalOrgId !== currentOrgId
})

const selectCloakingInfo = createSelector(selectCloakedOrg, selectIsCloaking, (cloakedOrg, isCloaking) => {
  return { ...cloakedOrg, isCloaking }
})

export const useCurrentOrgId = selectorHookFactory(selectCurrentOrgId)
export const useCloakingInfo = selectorHookFactory(selectCloakingInfo)
export const usePrincipalOrgId = selectorHookFactory(selectPrincipalOrgId)
export const useIsCloaking = selectorHookFactory(selectIsCloaking)

export const useSetPrincipleOrganization = dispatcherFactory(setPrincipalOrganization)
export const useCloakAsOrganization = dispatcherFactory(cloakAsOrganization)
export const useRevertToPreviousOrganization = dispatcherFactory(revertToPreviousOrganization)
export const useResetCloaking = dispatcherFactory(resetCloaking)

/**
 * Initiates "cloaking" as the provided organization
 */
export function useCloaking() {
  const dispatch = useDispatch()
  const cloakAsOrganization = useCloakAsOrganization()

  return useCallback(
    (org: OrganizationModel, parentOrg: OrganizationModel) => {
      // Update this slice to indicate the new cloaked org
      cloakAsOrganization({
        name: org.organizationNm,
        parentName: parentOrg.organizationNm,
        id: org.id
      })

      // Perform updates to other slices
      dispatch(resetVehiclesOverview())
      dispatch(resetSitesOverview())
    },
    [dispatch, cloakAsOrganization]
  )
}
