import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { useCurrentOrgId } from '~/store/slices/currentOrg'
import { dispatcherFactory, selectorHookFactory } from '~/store/utils'

type ChargerOverviewSlice = {
  orgIds: string[]
  siteIds: string[]
}

const initialState: ChargerOverviewSlice = {
  orgIds: [],
  siteIds: []
}

const chargerOverviewSlice = createSlice({
  name: 'chargerOverview',
  initialState,
  reducers: {
    reset: () => initialState,
    setOrgIds: (state, action: PayloadAction<string[]>) => ({ ...state, orgIds: action.payload }),
    setSiteIds: (state, action: PayloadAction<string[]>) => ({ ...state, siteIds: action.payload })
  }
})

export default chargerOverviewSlice.reducer

export const useSelectedOrgIds = selectorHookFactory((state) => state.chargerOverview.orgIds)
export const useSelectedSiteIds = selectorHookFactory((state) => state.chargerOverview.siteIds)
export const useFilterCount = selectorHookFactory(
  (state) => (state.chargerOverview.orgIds.length > 0 ? 1 : 0) + (state.chargerOverview.siteIds.length > 0 ? 1 : 0)
)

const { reset, setOrgIds, setSiteIds } = chargerOverviewSlice.actions
export { reset }

export const useUpdateOrgIds = dispatcherFactory(setOrgIds)
export const useUpdateSiteIds = dispatcherFactory(setSiteIds)

/** Returns the selected org ID or the current org ID if no org is selected */
export function useSelectedOrgIdOrCurrent() {
  const orgIds = useSelectedOrgIds()
  const orgId = useCurrentOrgId()
  return orgIds.length > 0 ? orgIds : [orgId]
}
