import type { ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '.'

/**
 * Converts a selector into a hook that can be used in a functional component.
 */
export function selectorHookFactory<T>(selector: (state: RootState) => T) {
  return () => useSelector(selector)
}

/** Wraps an action creator without a payload in a hook that can be used in a functional component. */
export function dispatcherFactory(acwp: ActionCreatorWithoutPayload): () => () => PayloadAction<undefined, string>

/** Wraps an action creator with a payload in a hook that can be used in a functional component. */
export function dispatcherFactory<P>(acwp: ActionCreatorWithPayload<P>): () => (payload: P) => PayloadAction<P, string>

/**
 * Wraps an action creator in a hook that can be used in a functional component.
 */
export function dispatcherFactory<P>(actionCreator: ActionCreatorWithPayload<P>) {
  return function useDispatchedAction() {
    const dispatch = useDispatch()
    return useCallback((payload: P) => dispatch(actionCreator(payload)), [dispatch])
  }
}
